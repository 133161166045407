@import "~antd/dist/antd.less";
@import "./partials/collapseBox.less";
@import "./partials/dashboard.less";

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.dashboard-layout {
  background: "#FFF";
}

[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}

.ant-layout-sider-zero-width-trigger {
  top: 5px;
  right: 10px;
  color: #001529;
  background: none;
  font-size: 20px;
}
// .ant-layout-sider-collapsed > .ant-layout-sider-zero-width-trigger {
//   right: -50px;
// }

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 32px;
  height: 38px;
  margin-left: -16px;
  margin-top: -19px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ant-avatar {
  float: right;
  margin-right: 30px;
  margin-top: 15px;
}

.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  background: #fff;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #fff;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: rgba(255, 255, 255, 0.5);
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #fff;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: rgba(255, 255, 255, 0.5);
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #fff;
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: rgba(255, 255, 255, 0.5);
}

.account-validation-header-right-action {
  float: right;
  text-align: right;
  width: 100%;
}

.horizontal-form-container {
  display: flex;
  flex-direction: row;
}

.ant-dropdown-menu {
  border-radius: 6px;
}

.login-form button[type="submit"] {
  margin-bottom: 20px;
}

.login-form-forgot {
  float: right;
}

.login-form-register-now {
  align-items: center;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

.login-form-button {
  width: 100%;
  background-color: #fcb900;
  border-color: transparent;
  color: #000;
}

.form-centered-container {
  width: 100%;
  text-align: center;
}

.otp-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: center;
  text-align: center;
}

.otp-form-resend {
  display: flex;
  justify-content: center;
}

.clickRowStyl {
  background: #00b4ed;
}

.ant-table * {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.ant-table-row-selected > td {
  background: #d8e6df !important;
}

.ant-tooltip-inner {
  color: #000;
  background-color: #fcb900;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: #fcb900;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}

.ant-layout-header {
  padding-left: 20px;
}

.App-link {
  color: #61dafb;
}

.ant-layout-content {
  flex: none;
  min-height: 0;
}

.account-validation-footer-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.account-validation-footer-section {
  width: 30%;
}

.ant-upload.ant-upload-select {
  display: block;
}

.ant-layout-footer {
  padding: 24px 50px;
  color: "#FFF";
  font-size: 14px;
  background: #f0f2f5;
}

.ant-input-group-addon {
  display: none;
}

.ant-select,
.ant-select-single,
.ant-select-show-arrow {
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@primary-color: #FCB900;